import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCustomer, getCustomerById, signIn } from './userApiHandler';
import { navigate } from 'gatsby';

const UserContext = createContext({
  user: {},
  signInHandler: () => {},
  logoutHandler: () => {},
});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const pathname = document.location.pathname;

  const signInHandler = async (credential) => {
    // const res = await signIn(credential);
    // if (res?.accessToken) {
    const data = await getCustomer(credential.email);
    const customerData = data?.results?.length ? data.results[0] : {};
    if (customerData.id) {
      localStorage.setItem('customerId', customerData.id);
      setUser(customerData);
      navigate('/');
    }
    // }
  };

  useEffect(() => {
    (async () => {
      const storedCustomerId = localStorage.getItem('customerId');
      if (storedCustomerId) {
        if (pathname.includes('login')) {
          navigate('/');
        }
        const data = await getCustomerById(storedCustomerId);
        setUser(data);
      } else if (!pathname.includes('login')) {
        navigate('/login');
      }
    })();
  }, []);

  const logoutHandler = () => {
    setUser({});
    localStorage.removeItem('customerId');
    navigate('/login');
  };

  return (
    <UserContext.Provider
      value={{
        user,
        signInHandler,
        logoutHandler,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
export const useUser = () => useContext(UserContext);
