import React from 'react';

import { NotificationProvider } from './src/context/AddItemNotificationProvider';
import UserProvider from './src/context/UserProvider';

export const wrapRootElement = ({ element }) => (
  <UserProvider>
    <NotificationProvider>{element}</NotificationProvider>
  </UserProvider>
);
